import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { HomeLayoutComponent } from './layouts/home-layout.component';
import { NavbarLayoutComponent } from './layouts/navbar-layout.component';
import { LandingPageComponent } from './pages/landing-page/landing-page.component';
import { AuthGuard } from '@core/guards/auth.guard';

const routes: Routes = [
  {
    path: '',
    component: HomeLayoutComponent,
    children: [
      {
        path: 'login',
        loadChildren: () => import('./auth/auth.module').then((m) => m.AuthModule),
      },
      {
        path: '',
        component: LandingPageComponent,
      },
      {
        path: '',
        loadChildren: () => import('./modules/user-onboarding/user-onboarding.module').then((m) =>m.UserOnboardingModule)
      }
    ]
  },
  {
    path: '',
    component: NavbarLayoutComponent,
    canActivate: [AuthGuard],
    children: [
      {
        path: 'execute-feature',
        loadChildren: () => import('./modules/stage4-execute/stage4-execute.module').then((m) => m.Stage4ExecuteModule),
      },
      {
        path: 'settings',
        loadChildren: () => import('./modules/settings/settings.module').then((m) => m.SettingsModule),
      },
      {
        path: 'journeys',
        loadChildren: () => import('./modules/stage1-journeys/stage1-journeys.module').then((m) => m.Stage1JourneysModule),
      },
      {
        path: 'featurefiles',
        loadChildren: () => import('./modules/stage2-featurefiles/stage2-featurefiles.module').then((m) => m.Stage2FeaturefilesModule),
      },
      {
        path: 'link',
        loadChildren: () => import('./modules/stage3-link/stage3-link.module').then((m) => m.Stage3LinkModule),
      },
      {
      path: 'dashboard',
        loadChildren: () => import('./modules/dashboard/dashboard.module').then((m) => m.DashboardModule),
      }
     
    ]
  },
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
