import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from '@env';

@Injectable({
  providedIn: 'root'
})
export class PagesServiceService {

  constructor(private httpClient: HttpClient) { }


getInquiry(req: any){
  return this.httpClient.post(environment.inquiryForm , req);
}

}
