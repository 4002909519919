import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { OwlOptions } from 'ngx-owl-carousel-o';
import * as AOS from 'aos';
import counterUp from 'counterup2';
import { PagesServiceService } from '../pages-service.service';
import { NotifierService } from '@utils/notifier.service';

@Component({
  selector: 'app-landing-page',
  templateUrl: './landing-page.component.html',
  styleUrls: ['./landing-page.component.scss']
})
export class LandingPageComponent implements OnInit {

  // banner slider options
  bannerOwlOptions: OwlOptions = {
    items: 1,
    loop: true,
    mouseDrag: false,
    touchDrag: false,
    pullDrag: false,
    dots: false,
    navSpeed: 800,
    autoplay: true,
    autoplayHoverPause: true,
    autoplaySpeed: 800,
    animateIn: 'fadeIn',
    animateOut: 'fadeOut',
    nav: true,
    navText: ['', ''],
    responsive: {
      0: {
        items: 1
      }
    },
  }

  // testimonials slider options
  testimonialsOwlOptions: OwlOptions = {
    items: 1,
    loop: false,
    mouseDrag: true,
    touchDrag: true,
    pullDrag: true,
    dots: false,
    navSpeed: 700,
    autoplay: false,
    autoplaySpeed: 700,
    nav: true,
    navText: ['', ''],
    responsive: {
      0: {
        items: 1
      }
    }
  }

  // form
  salesEnquiryForm: FormGroup | any;


  constructor(
    private formBuilder: FormBuilder,
    private pageService: PagesServiceService,
    private notifier: NotifierService,
  ) { }

  ngOnInit() {
    
    // aos init
    AOS.init({
      once: true,
      // anchorPlacement: 'bottom',
      // offset: 0
    });

    // counter scroll
    const IO = new IntersectionObserver(this.isCounterVisible, { threshold: 1 });
    var counter1: any = document.querySelector('.item-count-1');
    var counter2: any = document.querySelector('.item-count-2');
    var counter3: any = document.querySelector('.item-count-3');
    IO.observe(counter1);
    IO.observe(counter2);
    IO.observe(counter3); /**/

    this.buildForm();

  }

  get f() {
    return this.salesEnquiryForm.controls
  }

  buildForm() {
    // registration form
    this.salesEnquiryForm = this.formBuilder.group({
      // email: ["", [Validators.required, Validators.email, Validators.pattern('^[a-z0-9._%+-]+@[a-z0-9.-]+\\.[a-z]{2,4}$')]],
      name: ['', [Validators.required, Validators.pattern('[a-zA-Z ]*')]],
      email: ['', [Validators.required, Validators.email]],
      subject: ['', [Validators.required]],
      phone: ['', [Validators.required]],
      message: ['', [Validators.required]]
    });
    // console.log(this.salesEnquiryForm.controls)
   
  }

  isCounterVisible = (entries: any) => {
    entries.forEach((entry: any) => {
      const el = entry.target;
      let previousY = 0;
      if (entry.isIntersecting && !el.classList.contains('is-visible')) {
        counterUp(el, {
          duration: 1300,
          delay: 16,
        });
      }

      const currentY = entry.boundingClientRect.y;
      const isIntersecting = entry.isIntersecting;
      if (currentY < previousY) {
        el.classList.add('is-visible');
      }
      else if (currentY > previousY && isIntersecting) {
        el.classList.remove('is-visible');
      }

      previousY = currentY;
    });
  };

  // userRegistrationFormSubmit() {
  //   this.submitted = true;
  //   // stop here if form is invalid
  //   if (this.userRegistrationForm.invalid) {
  //     return;
  //   }
  //   console.log('Email: ', this.userRegistrationForm.value);
  // }

  salesEnquiryFormSubmit() {
    if (this.salesEnquiryForm.invalid) {
      return;
    }

    let req = {
      contactEmail: this.salesEnquiryForm.controls.email.value,
      contactName: this.salesEnquiryForm.controls.name.value,
      phone: this.salesEnquiryForm.controls.phone.value,
      message: this.salesEnquiryForm.controls.message.value
    }
    console.log(req)
   

    this.pageService.getInquiry(req).subscribe((resp: any) => {
      console.log(resp.data)
    this.notifier.success(
      'Done!',
      'form submitted!.'
    );   
      //  this.submitted = true
    this.salesEnquiryForm.reset()
    },
    (error) => {
      this.notifier.error(
        'Failed',
      'Try Again'
      );
      // this.submitted = true
      }
    )
   
  }



}

