<div class="nav sticky" id="navbar">
    <div class="navbar-menu-left">
        <div class="logo">
            <a href="#">
                <img class="logo1" src="../../../assets/images/ecastLogo1.png">
            </a>
        </div>
        <!--Home menu -->
        <div class="menu-item" [ngClass]="{ active: tab === 'dashboard' }" (click)="goToDashboard();tab='dashboard'">
            Dashboard
        </div>
        <!-- Journeys menu -->
        <div class="menu-item" [ngClass]="{ active: tab === 'journeys' }" (click)="goToJourneys();tab='journeys'">
            Journeys
        </div>
        <!-- Feature files menu -->
        <div class="menu-item" [ngClass]="{ active: tab === 'featurefiles' }"
            (click)="goToFeatureFiles();tab='featurefiles'">Feature
            files</div>
        <!-- Link menu -->
        <div class="menu-item" [ngClass]="{ active: tab === 'link' }" (click)="goToLink();tab='link'">Link</div>
        <!-- Execute Menu  -->
        <div class="menu-item" [ngClass]="{ active: tab === 'execute-feature' , active2 : tab === 'execute-feature/execution-report' }"
            (click)="gotoExecute();tab='execute-feature'">
            Execute
            <span class="pd-10"></span>
            <!-- <button (click)="gotoExecute();tab='execute-feature'" class="dropdownButton">Execute Features</button> -->
            <!-- <i class="fa fa-caret-down"></i> -->
            <!-- Menu dropdown -->
            <!-- <div class="dropdown">
                <button (click)="gotoExecute();tab='execute-feature'" class="dropdownButton">Execute Features</button>
                <button (click)="gotoHistory();tab='execute-feature/history'" class="dropdownButton">View History</button>
            </div> -->
        </div>
    </div>
    <div class="navbar-menu-right">
        <!-- user menu -->
        <div class="avatar">
            <img class="avatar" [src]="userAvatarImage">

            <div class="avatar-dropdown">

                <div class="dropdown-project-info">
                    <div><img id="logo" [src]="projectLogo"></div>
                    <p class="ellipsify-text">{{projectName}}</p>
                </div>
                <div class="dropdown-user-info">
                    <div class="user-name">{{name}}</div>
                    <div class="user-email">{{email}}</div>
                </div>
                <div class="dropdown-link">
                    <div><img id="menu-icon" src="../../../assets/images/settings1.svg"></div>
                    <div><button class="profile-dropdown-item-btn" (click)="goToSettings();tab='any'">Settings</button>
                    </div>
                </div>
                <div class="dropdown-link">
                    <div><img id="menu-icon" src="../../../assets/images/signOutIcon.svg"></div>
                    <div><button class="profile-dropdown-item-btn" (click)="signout()">SignOut</button></div>
                </div>
            </div>
        </div>
        <!-- project logo and name -->
        <div class="project-menu">
            <div class="logo-outer-div" [ngClass]="{'active' : isMultiProject}">
                <div id="project-logo-img" [ngClass]="{'active' : isMultiProject}"><img [src]="projectLogo"></div>
                <div id="project-logo-name" [ngClass]="{'active' : isMultiProject}">
                    <p>{{projectName}}</p>
                </div>
                <div class="project-dropdown" *ngIf="projectList && projectList.length>1">
                    <ng-container *ngFor="let project of projectList">
                        <!--  [ngClass] = "{'selected' : project.sp_project_name == projectName}"  -->
                        <div class="project-item">
                            <div class="select-icon" [ngClass]="{'selected' : project.sp_project_name == projectName}">
                                <!-- <ng-container *ngIf="project.sp_project_name == projectName"> -->
                                <i class="fa fa-check" aria-hidden="true"></i>

                                <!-- </ng-container> -->
                            </div>
                            <button (click)="switchProject(project)" class="project-btn">

                                <div class="btn-txt" style=" overflow: hidden;
                               
                                text-overflow: ellipsis;">{{project.sp_project_name}}</div>
                            </button>
                        </div>
                    </ng-container>
                </div>
            </div>
        </div>
    </div>
</div>