

import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable, OnInit } from '@angular/core';
import { AuthService } from '@auth/auth.service';
import { PROJECT_ID_KEY, PROJECT_CODE_KEY, USER_ID, USER_IP_ADDRESS } from '@core/constants/local-storage-keys.constants';
import { environment } from '@env';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class CardService  {
  // BASE_URL = environment.baseurl;
  token: String | any;

  devices: any;
  browsers: any;
  servers: any;
  executeCount: any;
  projectID: string | any;
  userID: string | any;
  getIpAddress = localStorage.getItem(USER_IP_ADDRESS) || "null";
  
  headerOptions = {
    headers: new HttpHeaders({
      'Content-Type': 'application/json',
      'IPAddress' : this.getIpAddress
    }),
  };
  constructor(private httpClient: HttpClient, private _authService: AuthService) { 
    _authService.userInfo.subscribe((user:any)=>{
      if(Object.keys(user).length == 0){
        this.projectID = this.getProjectId();
      }
      else{
        this.projectID = user.data.userInfo.su_projects[0].sp_id;
      }
    }
    );
    this.userID = this.getUserId()
  }

  getJourneyList(pageNumber:number) {
    if(pageNumber == -1) {
      return this.httpClient.get(environment.allJourneyAPI+`?suj_project_id=`+this.projectID, this.headerOptions);
    } else {
      return this.httpClient.get(environment.journeyAPI+`?suj_project_id=`+this.projectID+`&page=`+pageNumber+`&size=10`, this.headerOptions);
    }
   
  }
  getJourneyListPaginator(pageNumber: number) {
    return this.httpClient.get(environment.journeyAPI+ `?suj_project_id=`+this.projectID+`&page=`+pageNumber+`&size=10`, this.headerOptions);
  }

  executeFeatures(data: any) {
    // console.log(data)
    return this.httpClient.post(environment.executionAPI, data, this.headerOptions);
  }

  getAllExecutionHistory(){
    return this.httpClient.get(environment.historyAPI + '?suj_project_id='+this.projectID, this.headerOptions);
  }

  getExecutionHistoryByDate(startDate: string, endDate:string){
    return this.httpClient.get(environment.historyAPI + '?suj_project_id='+this.projectID+'&suj_date_from='+startDate+'&suj_date_to='+endDate, this.headerOptions);
  }

  getUserId(){
    return localStorage.getItem(USER_ID);
  }

  getExecutionName(){
    return this.httpClient.get(environment.executionNameAPI + '?sje_project_id='+this.projectID+'&su_id='+this.userID, this.headerOptions);
  }
  
  getCurrentExecution(){
    return this.httpClient.get(environment.viewExecute + '?sje_project_id='+this.projectID, this.headerOptions)
  }
  
  stopExecution(data: any){
    console.log('in stop execution ', data);
    console.log('environment.stopExecutionApi ',environment.stopExecutionApi)
    return this.httpClient.post(environment.stopExecutionApi, data, this.headerOptions)
  }

  setExecuteCount(data: any) {
    this.executeCount = data
  }

  getExecuteCount() {
    return this.executeCount;
  }

  getProjectDetails() {
    return this.httpClient.get(environment.projectAPI + `?suj_project_id=`+this.projectID+`&suj_customer_id=1`, this.headerOptions);
  }

  setProjectCode(projectCode:string){
    localStorage.setItem(PROJECT_CODE_KEY, projectCode)
  }

  getProjectCode(){
    return localStorage.getItem(PROJECT_CODE_KEY)
  }

  getProjectId() {
    return localStorage.getItem(PROJECT_ID_KEY);
  }

  getJourneyTags(){
    return this.httpClient.get(environment.getJourneyTags + '?suj_project_id='+this.projectID, this.headerOptions)
  }
}

