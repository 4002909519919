const apiurl = 'https://8d6lo2ff39.execute-api.eu-west-2.amazonaws.com/stg'

export const environment = {
  baseurl:'https://45ytn5lup2.execute-api.eu-west-2.amazonaws.com/stg/v1',
  production: true,
  executionAPI: 'https://iycei9ey4k.execute-api.eu-west-2.amazonaws.com/stg/executeTest',
  journeyAPI: 'https://8d6lo2ff39.execute-api.eu-west-2.amazonaws.com/stg/v1/journey',
  // journey api without pagination
  allJourneyAPI: apiurl+'/journey',
  historyAPI: apiurl+'/execution-report-history',
  reportAPI: 'https://8d6lo2ff39.execute-api.eu-west-2.amazonaws.com/stg/execution-report',
  projectAPI: 'https://ppm7pp5cti.execute-api.eu-west-2.amazonaws.com/stg/project',

  // needs to be updated with the exact URL 
  agentAPI: 'https://ppm7pp5cti.execute-api.eu-west-2.amazonaws.com/stg/agents',

  imageRepoURL: 'https://ecast-client.s3.eu-west-2.amazonaws.com',
  screenshotRepoURL: 'https://ecast-stg-s3.s3.eu-west-2.amazonaws.com',
  getAllJourneys: apiurl+'/stage1/pagejourney',
   createJourneys: apiurl+'/stage1/journeypage',
   getSelectedJourneys: apiurl+'/stage1/journey',
   deleteStep: apiurl+'/scenario-page',
   deleteJourney: apiurl+'/stage1/journey',
   getScenario: apiurl+'/scenario',
   saveStage1: apiurl+'/journey',
   createFeatureFile: apiurl+'/featurefile',
   sitemapPageAPI: apiurl+'/pageflow',
   executionNameAPI: apiurl+'/current-execution',
   viewExecute: apiurl+'/current-execution',
   createJourneyWithoutTemplate: apiurl+'/stage1/journey',
   createNewScenario: apiurl+'/scenario',
   updateScenario: apiurl+'/scenario-page',
   getIpAddressAPI: 'https://ipinfo.io/json',
   addStep: apiurl+'/step',
   createJourneyTag: apiurl+'/journey-tag',
   scenarioTag: apiurl+'/scenario-tag',
   deleteFeature: apiurl+'/feature',
   stepSuggestions: apiurl+'/getStepSuggestions',
  downloadFiles: apiurl+'/download-featurefile',
  createPage: apiurl+'/sitemap',
  getPageWithoutJourney:  apiurl+"/pages",
  deletePage: apiurl+"/sitemap-delete",
  updateProject:apiurl+'/project/update',
  stopExecutionApi: apiurl + "/stop-execution",
  getJourneyTags: apiurl + "/journeytags",
  inquiryForm: "https://hbjqff8146.execute-api.eu-west-2.amazonaws.com/development/inquiry",
  createUser: "https://hbjqff8146.execute-api.eu-west-2.amazonaws.com/development/register",
  resetPassword: "https://hbjqff8146.execute-api.eu-west-2.amazonaws.com/development/password",
  userAPI: 'https://tsacw22qwi.execute-api.eu-west-2.amazonaws.com/production/user',
  customerAPI: 'https://syslt5arck.execute-api.eu-west-2.amazonaws.com/production/customer',
  settingsUploadImage: 'https://syslt5arck.execute-api.eu-west-2.amazonaws.com/production/upload-logo',
  // imageRepoURL: 'https://ecast-client.s3.eu-west-2.amazonaws.com',
  // screenshotRepoURL: 'https://ecast-prod-s3.s3.eu-west-2.amazonaws.com',



};
