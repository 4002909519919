import { Injectable } from '@angular/core';
import { IndividualConfig, ToastrService } from 'ngx-toastr';

@Injectable({
  providedIn: 'root',
})
export class NotifierService {

  options: IndividualConfig;

  constructor(private toastr: ToastrService) { 
    this.options = this.toastr.toastrConfig;
    this.options.closeButton = true
  }

  info(title: string,message: string) {
    this.toastr.info(message, title);
  }

  success(title: string, message: string) {
    this.toastr.success(message, title);
    this.options.closeButton = true
  }

  error(title: string, message: string) {
    this.toastr.error(message, title);
  }

  warning(title: string, message: string) {
    this.toastr.warning(message, title);
  }
}
