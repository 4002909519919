import { NgModule } from '@angular/core';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';

import { LandingPageComponent } from './pages/landing-page/landing-page.component';
import { HomeLayoutComponent } from './layouts/home-layout.component';
import { NavbarLayoutComponent } from './layouts/navbar-layout.component';

import { CoreModule } from '@core/core.module';
import { ChartsModule } from 'ng2-charts';

import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatButtonModule } from '@angular/material/button';
import { CarouselModule } from 'ngx-owl-carousel-o';
import { CommonModule } from '@angular/common';

@NgModule({
  declarations: [
    AppComponent,
    LandingPageComponent,
    HomeLayoutComponent,
    NavbarLayoutComponent,
  ],
  imports: [
    AppRoutingModule,
    CoreModule,
    ChartsModule,
    CarouselModule,
    MatButtonModule,
    FormsModule, 
    ReactiveFormsModule,
    CommonModule,
  ],
  providers: [ ],
  bootstrap: [AppComponent],
})
export class AppModule { }
