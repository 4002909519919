import { AfterViewInit, Component, OnChanges, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { AuthService } from '@auth/auth.service';
import { ROUTES } from '@core/constants/constants'
import { PROJECT_CODE_KEY, PROJECT_ID_KEY, PROJECT_LIST_KEY, PROJECT_LOGO_KEY, PROJECT_NAME_KEY, USER_EMAIL_KEY, USER_FULLNAME_KEY, USER_ID, USER_REFRESH_TOKEN } from '@core/constants/local-storage-keys.constants';
import { environment } from '@env';
import { CardService } from '../../../modules/stage4-execute/services/card.service';

@Component({
  selector: 'app-navbar',
  templateUrl: './navbar.component.html',
  styleUrls: ['./navbar.component.scss']
})
export class NavbarComponent implements OnInit {
  show: boolean = false;
  list2: any;
  label2 = "Desktop Browsers"
  public projectData: any;
  projectName: any
  projectLogo: any;
  name: any;
  email: any;
  projectList: any | undefined = [];
  isMultiProject: boolean = this.projectList.length > 1;
  tab: any;
  userAvatarImage: any;
  userID: any;
  
  constructor(
    public _authService: AuthService,
    private _router: Router,
    private cardService: CardService,

  ) {
    this.tab = this._router.url.replace('/', '');
    console.log(this.tab)


  }

  ngOnInit(): void {
    this.name = localStorage.getItem(USER_FULLNAME_KEY);
    this.email = localStorage.getItem(USER_EMAIL_KEY);
    this.userID = localStorage.getItem(USER_ID)

    this.projectName = localStorage.getItem(PROJECT_NAME_KEY);
    this.projectLogo = environment.imageRepoURL + '/' + localStorage.getItem(PROJECT_LOGO_KEY);

    var projectList = JSON.parse(localStorage.getItem(PROJECT_LIST_KEY) || '[]');
    if (projectList) {
      this.projectList = projectList;
    }

    this.isMultiProject = this.projectList.length > 1;

    if (this.userID == 1 || this.userID == 2 || this.userID == 3) {
      this.userAvatarImage = "../../assets/images/av3.PNG"
    } else if (this.userID == 4 || this.userID == 5 || this.userID == 6) {
        this.userAvatarImage = "../../assets/images/av4.jpg" 
    } else if (this.userID == 7 || this.userID == 8 || this.userID == 9) {
        this.userAvatarImage = "../../assets/images/av5.jpg"
    } else {
      this.userAvatarImage = "../../../../assets/images/avatar.png"
    }

    // } else if (this.userID == 4 || this.userID == 5 || this.userID == 6) {
    //   this.userAvatarImage = "../../assets/images/av4.jpg"
    // } else if (this.userID == 7 || this.userID == 8 || this.userID == 9) {
    //   this.userAvatarImage = "../../assets/images/av5.jpg"
    // } else {
    //   this.userAvatarImage = "../../assets/images/avatar.PNG"
    // }
  }

  showDetails() {
    this.show = true;
  }

  closeUserPopup() {
    this.show = false
  }

  signout() {
    this._authService.logout();
    this._router.navigate(['/']).then(() => {
      window.location.reload();
    });
  }
  goHome() {
    // this._router.routeReuseStrategy.shouldReuseRoute = () => false;
    // this._router.onSameUrlNavigation = 'reload';
    // this._router.navigateByUrl(ROUTES.HOME);
  }

  gotoExecute() {
    this._router.navigateByUrl(ROUTES.EXECUTE).then()
  }

  // gotoHistory() {
  //   this._router.navigateByUrl(ROUTES.EXECUTE + ROUTES.HISTORY)
  // }

  goToSettings() {
    this._router.navigateByUrl(ROUTES.SETTINGS)
  }

  switchProject(project: any) {

    if (this.projectName != project.sp_project_name) {
      localStorage.setItem(PROJECT_ID_KEY, project.sp_id);
      localStorage.setItem(PROJECT_NAME_KEY, project.sp_project_name);
      localStorage.setItem(PROJECT_LOGO_KEY, project.sp_project_logo);
      localStorage.setItem(PROJECT_CODE_KEY, project.sp_project_code);
      this._router.navigateByUrl(ROUTES.HOME).then(() => {
        window.location.reload();
      });
    }
  }


  goToJourneys() {
    this._router.navigateByUrl(ROUTES.JOURNEYS)
  }

  goToFeatureFiles() {
    this._router.navigateByUrl(ROUTES.FEATUREFILES)
  }

  goToLink() {
    this._router.navigateByUrl(ROUTES.LINK)
  }
  goToDashboard() {
    this._router.navigateByUrl(ROUTES.DASHBOARD)
  }

}
