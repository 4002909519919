export const USER_FULLNAME_KEY = 'su_fullname';
export const USER_EMAIL_KEY = 'su_email';
export const USER_TOKEN = 'token';
export const USER_REFRESH_TOKEN = 'refresh';
export const USER_ID = 'su_id';
export const CUSTOMER_ID_KEY = 'c_id';
export const PROJECT_NAME_KEY = 'p_name';
export const PROJECT_LOGO_KEY = 'p_logo';
export const PROJECT_CODE_KEY = 'p_code';
export const PROJECT_ID_KEY = 'p_id';
export const PROJECT_LIST_KEY = 'p_list';

export const FEATURE_KEY = 'feature';
export const SCENARIO_KEY = 'scenario';
export const FEATURE_HISTORY_KEY = 'featureHistory'
export const USER_IP_ADDRESS = 'su_ipAddress'
export const ROLE_ID = 'sr_id';